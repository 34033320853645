@import "~core/abstract/all";
@import "~bootstrap/scss/mixins";

.mobileFlex2r1cDesktop1r2cv2 {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
    }
}

.mobileFlex2r1cDesktop1r2cv2.mobile2r2cDesktop1r4c {
    padding: 0 20px !important;
    row-gap: 12px !important;

    @include media-breakpoint-up(sm) {
        padding: 0 40px !important;
        row-gap: 20px !important;
    }
    @include media-breakpoint-up(ls) {
        padding: 0 20px !important;
        column-gap: 20px !important;;
    }
}

@include media-breakpoint-down(xs) {
    .mobileFlex2r1cDesktop1r2cv2{
        padding: 0% !important;
    }
    .mobileFlex2r1cDesktop1r2cv2-c2{
        padding: 0% !important;
    }
    .planet-a-pd .mobileFlex2r1cDesktop1r2cv2-c2{
        padding: 10% !important;
    }
}